<template>
  <VueFinalModal @before-open="onBeforeOpen" name="pincode_set">
    <ModalBase name="pincode_set" titleIcon="alert" :title="$t('modal.profile.set_title')">
      <form method="post" action="/form" autocomplete="off" @submit.prevent="onSubmit" class="modal-pincode">
        <input autocomplete="false" name="hidden" type="text" style="display: none" />
        <div class="modal-pincode__text">
          {{ $t("modal.profile.set_text") }}
        </div>

        <div class="modal-pincode__secret">
          <VInput type="text" :readonly="isReadonly" autocomplete="off" required v-model="secret" :placeholder="$t('modal.profile.set_secret')" />
        </div>
        <PinInput v-model="code" required :characterPreview="false" secure class="modal-pincode__inputs" input-class="input" :length="4" autofocus placeholder="0" />
        <VButton type="submit" class="modal-pincode__btn" size="48"> {{ $t("modal.profile.setup") }} </VButton>
      </form>
    </ModalBase>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal, $vfm } from "vue-final-modal";
import { PinInput } from "v-pin-input";

const code = ref("");
const secret = ref("");
const isReadonly = ref(true);

function onBeforeOpen() {
  code.value = "";
  secret.value = "";
  isReadonly.value = false;
}
const { $axios, $auth } = useNuxtApp();

function onSubmit() {
  $axios
    .$post("/auth/changeCode", {
      code: code.value,
      secret: secret.value,
    })
    .then(({ response }) => {
      $auth.user!.code_required = true;
      if (response?.notify?.type === "success") $vfm.hide("pincode_set");
    });
}
</script>

<style lang="scss">
.modal-pincode {
  width: 322px;
  max-width: 100%;
  color: var(--color_main__100);

  &__secret {
    margin-bottom: 20px;
  }

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 137.3%;
    margin-bottom: 20px;
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 12px;

    .input {
      aspect-ratio: 1/1;
      background: var(--bg_secondary__100);
      text-align: center;
      border-radius: var(--b-radius);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;

      &::placeholder {
        color: var(--color_secondary__100);
      }
    }
  }

  &__btn {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
